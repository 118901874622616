#header {
    position: relative;
    z-index: 15;
    box-shadow: 0 5px 10px rgba(0,0,0,0.2);
    position: relative;
    z-index: 100;

    .container-xl {
        overflow: initial;
    }
 
    @media (max-width: 767px) {
        padding-top: 40px;
    }

    .btn-default.btn-lg {
        padding: 10px 16px;
        font-size: 18px;
        line-height: 1.3333333;
        border-radius: 6px;
        margin-top: 0;
        margin-bottom: 0;
    }

    .info-bar { 
        background: #474847;
        color: #fff;

        @media (max-width: 767px) {

            position: absolute;
            width: 100%;
            top: 0;
            z-index: 100;
            >.container{
                max-width: 100%;
                padding: 0;
            }
        }


        .contact-info {
            padding: 10px 0;
            padding-left: 0;

            ul {
                margin-bottom: 0;

                li {
                    a {
                        color: #fff;
                        font-size: 11px;
                        letter-spacing: 2px;

                        span {
                            width: 20px;
                            height: 20px;
                            background: $orange;
                            color: #fff;
                            border-radius: 50%;
                            margin-right: 5px;
                            display: inline-flex;
                            align-items: center;
                            justify-content: center;
                            text-align: center;
                            font-size: 11px;
                            font-weight: 700;
                        }

                        &:hover,
                        &:focus {
                            color: $green;
                        }
                    }

                    margin-right: 10px;
                }
            }
        }

        .social-media {

            ul {
                margin-bottom: 0;

                li {
                    display: inline-flex;
                    align-items: stretch;
                    padding: 0;

                    a {
                        display: flex;
                        align-items: center;
                        color: #fff;
                        width: 45px;
                        justify-content: center;



                        &.twitter {
                            background: #63cdf1;

                            &:hover,
                            &:focus {
                                background: darken(#63cdf1, 10%);
                            }
                        }

                        &.facebook {
                            background: #507cbe;

                            &:hover,
                            &:focus {
                                background: darken(#507cbe, 10%);
                            }
                        }

                        &.instagram {
                            background: #c7c5b3;

                            &:hover,
                            &:focus {
                                background: darken(#c7c5b3, 10%);
                            }
                        }


                    }
                }
            }

        }

        .mobile-buttons {
            ul {
                margin-bottom: 0;
                display: flex;
                align-items: stretch;

                li {
                    border-left: 1px solid #999999;
                    display: inline-flex;
                    align-items: stretch;

                    a {
                        display: flex;
                        align-items: center;
                        width: 40px;
                        justify-content: center;

                        i {
                            color: #fff;
                            font-size: 22px;
                        }


                    }

                    &:hover,
                    &:focus {
                        background: $purple;
                    }
                }
            }
        }
    }

    .logo-bar {
        padding: 20px 15px;
        //background-image: url('/image/header-bg.png');
        background-color: #fff;
        background-position: 50% 50%;
        background-size: auto;
        background-repeat: no-repeat;

        .logo {
            @media (max-width: 767px) {
                img {
                    width: 200px;
                }

            }
        }

        .account-section {
            flex-basis: 450px;
            max-width: 450px;

            >div {
                width: 100%;
            }

            @media (max-width: 991px) {

                flex-basis: 400px;
                max-width: 400px;
            }




            .left {
                flex-basis: 80%;
                max-width: 80%;

                .top-buttons {
                    margin-left: -5px;
                    margin-right: -5px;
                    display: flex;
                    flex-wrap: wrap;
                    flex-basis: 100%;
                    max-width: 100%;

                    >div {
                        padding: 0 5px;
                        flex-basis: 50%;
                        max-width: 50%;
                    }
                }

                .full-button {
                    width: 100%;
                    font-size: 11px;
                }

                #search {
                    padding-right: 10px;

                    .input-lg {
                        border-radius: 0px;
                        height: 46px;
                        font-size: 13px;
                    }

                    .btn-group-lg>.btn,
                    .btn-lg {
                        border-radius: 0px;

                        i {
                            color: $blue;
                        }
                    }
                }

            }

            .right {
                flex-basis: 20%;
                max-width: 20%;
                display: flex;
                align-items: stretch;

                #cart {
                    background: #fff;
                    flex-basis: 100%;
                    max-width: 100%;

                    a {
                        flex-basis: 100%;
                        max-width: 100%;

                    }

                    &:hover,
                    &:focus {
                        .basket-icon {
                            color: $orange;
                        }
                    }

                    .basket-icon {
                        font-size: 40px;
                        color: $blue;
                        text-align: center;
                        flex-basis: 100%;
                        max-width: 100%;
                        padding: 5px;
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        transition: all 0.3s;
                        .ammount {
                            font-size: 14px;
                            position: absolute;
                            top: 7px;
                            right: 7px;
                            z-index: 2;
                            background: $orange;
                            width: 20px;
                            height: 20px;
                            color: #fff;
                            border-radius: 50%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    }

                    #cart-total {
                        padding: 2px 5px;
                        background: $blue;
                        text-align: center;
                        color: #fff;
                        border-radius:10px;
                        font-weight: 700;
                        font-size: 13px;

                    }
                }
            }

            @media (max-width: 767px) {
                display: none;
                flex-basis: 100%;
                max-width: 100%;
                position: absolute;
                top: 40px;
                padding: 10px;
                left: 0;
                right: 0;
                background: #fff;
                border-bottom: 1px solid #ccc;
                box-shadow: 0px 11px 18px -10px rgba(0, 0, 0, 0.73);
                -webkit-box-shadow: 0px 11px 18px -10px rgba(0, 0, 0, 0.73);

                .left {
                    flex-basis: 100%;
                    max-width: 100%;

                    #search {
                        padding-right: 0px;

                        .input-lg {
                            font-size: 16px;
                        }

                    }
                }

                .right {
                    display: none;
                }
            }

            @media (min-width: 768px) {
                display: flex !important;
            }


        }



    }

    .mobile-bar {
        margin-right: 20px;

        .dl-menu {
            .full-button {
                margin: 0;

                &.purple {
                    &:hover {
                        background: darken($purple, 10%);
                    }
                }

                &.green {
                    &:hover {
                        background: darken($green, 10%);
                    }
                }
            }
        }

        ul {
            padding: 0;
            margin: 0;

            li {
                a {

                    &.tel {
                        background: #000;
                        color: #fff;
                        font-size: 12px;
                        padding: 0 15px;
                        height: 100%;
                        border-bottom: 0;

                        &:hover,
                        &:focus {
                            text-decoration: none;
                            color: #fff;
                            background: darken($red, 10%);

                        }
                    }

                    &.email {
                        background: $red;
                        color: #fff;
                        font-size: 12px;
                        padding: 0 15px;
                        height: 100%;
                        border-bottom: 0;

                        &:hover,
                        &:focus {
                            text-decoration: none;
                            color: #fff;
                            background: darken($red, 10%);

                        }
                    }

                }
            }
        }

    }



    .top-bar {

        position: relative;
        z-index: 20;
        background: #e4e4e4;

        @media (max-width: 991px) {
            >.container {
                max-width: 100%;
            }
        }

        @media (max-width: 767px) {
            background-position: -40px 0;
            position: fixed;
            top: 0;
            width: 100%;
            left: 0;
            border-top: 0 solid $red;
        }






        .menu-bar {
            border: 0px;
            border-radius: 0px;

            margin-bottom: 0;


            .main-menu {

                flex-basis: 100%;
                max-width: 100%;
                position: relative;
                >li {

                    flex-grow: 1;
                    display: flex;
                    align-items: stretch;
                    justify-content: center;
                    margin-left: 0;

                    &.brands-dropdown{
                        position: static;
                        
                        .heading{
                            font-size: 16px;
                            font-weight: bold;
                        }

                        .all-brands{
                            background: $orange;
                            color: #fff;
                            text-align: center;
                            padding: 10px 20px;
                            margin-top: 20px;
                            &:hover, &:focus{
                                background: $blue;
                            }
                        }

                        .mega-menu{
                            position: absolute;
                            top: 100%;
                            left: 0;
                            border: 1px solid #ccc;
                            background: #fff;
                            padding: 20px 20px;
                            width: 100%;
                            visibility: hidden;
                            opacity: 0;
                            z-index: 1;
                            transform-origin: top;
                            transform: scaleY(0);
                            z-index: -1;
                            transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;

                            .brand-container{
                                margin-left: -20px;
                                margin-right: -20px;
                                display: flex;
                                align-items: center;
                                justify-content: flex-start;
                                flex-wrap: wrap;
                                .popular-brand{
                                    padding-left: 20px;
                                    padding-right: 20px;
                                    flex-basis: 20%;
                                    max-width: 20%;
                                    margin: 10px 0;
                                    
                                    img{
                                        padding: 0 30px;
                                        max-width: 100%;
                                        @media (max-width: 991px) {
                                            padding: 0 20px;
                                        }
                                    }
                                    text-align: center;
                                    h5{
                                        color: #666;
                                    }

                                    &:hover, &:focus{
                                        h5{
                                            color: #f29a2e;
                                        }
                                        
                                    }
                                }
                            }
                        }

                        &:hover, &:focus{
                            .mega-menu{
                                visibility: visible;
                                opacity: 1;
                                z-index: 100;
                                transform: scaleY(1);
                                transition-delay: 0s, 0s, 0.3s;
                            }
                        }
                    }

                    >a {

                        border: 1px solid #fff;
                        border-top: 0;
                        border-bottom: 0px;
                        border-right: 0px solid #fff;
                        color: #666;
                        background: #ececec;
                        font-size: 13px;
                        cursor: pointer;
                        padding: 0 20px;
                        text-align: center;
                        letter-spacing: 2px;
                        text-transform: uppercase;
                        border-radius: 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-basis: 100%;
                        max-width: 100%;
                        min-height: 50px;

                        @media (max-width: 1199px) {


                            padding: 0 10px;
                            font-size: 12px;
                            letter-spacing: 1px;
                        }

                        @media (max-width: 991px) {



                            letter-spacing: 0px;
                        }


                    }

                    &:hover,
                    &:focus {
                        >a {
                            background: #c2c2c2;
                            color: #fff;
                        }

                        .submenu {

                            visibility: visible;
                            opacity: 1;
                            z-index: 1;
                            transform-origin: top;
                            transform: scaleY(1);
                            transition-delay: 0s, 0s, 0.3s;
                        }
                    }

                    &.active {
                        >a {
                            background: #c2c2c2;
                            color: #fff;

                            &:hover,
                            &:focus {
                                color: #fff;
                            }
                        }

                    }

                    .submenu {
                        opacity: 0;
                        visibility: hidden;
                        position: absolute;
                        list-style: none;

                        background: #fff;
                        top: 100%;
                        left: 0;
                        width: 100%;
                        background: #fff;
                        border: 1px solid #ccc;
                        transform-origin: top;
                        transform: scaleY(0);
                        z-index: -1;
                        transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;
                        padding: 15px 20px;
                        margin-bottom: 0;

                        li {
                            background: #fff;

                            a {
                                color: #666;
                                padding: 3px 0;
                                font-weight: bold;
                                font-size: 16px;
                                font-weight: 300;
                                background: #fff;
                                display: block;

                                &:hover,
                                &:focus {
                                    color: $green;
                                    text-decoration: none;
                                }
                            }


                        }


                    }

                    &:nth-child(1),
                        {
                        >a {
                            background: $green;
                            color: #fff;
                            font-weight: 900;

                            &:hover,
                            &:focus {
                                background: darken($green, 10%);
                            }
                        }
                    }


                    &:first-child {
                        a {
                            border-left: 0px solid #fff;
                        }

                    }
                }
            }


        }

    }

    #sticky-navbar {
        -webkit-transform: translateX(0px) translateY(-100%) translateZ(0px);
        -moz-transform: translateX(0px) translateY(-100%) translateZ(0px);
        -o-transform: translateX(0px) translateY(-100%) translateZ(0px);
        transform: translateX(0px) translateY(-100%) translateZ(0px);
        transform-style: preserve-3d;
        position: fixed;
        opacity: 0;
        visibility: hidden;
        width: 100%;
        z-index: 999;
        margin-top: 0;
        border-bottom: 1px solid #ccc;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
        background-color: #fff !important;
        background-size: 155px 100%;
        top: 0;
        left: 0;
        width: 100%;

        .menu-bar .main-menu>li {

            a {


                @media (max-width: 991px) {

                    letter-spacing: 1px;
                }
            }

            &.active {
                >a {

                    color: #fff;

                }
            }

            .submenu {
                border: 1px solid #ccc;

                li {
                    a {

                        &:hover,
                        &:focus {
                            color: $green;
                            text-decoration: none;
                        }
                    }
                }
            }
        }


        .logo {
            flex-basis: 200px;
            max-width: 200px;
        }

        &.show {
            opacity: 1;
            visibility: visible;
            transform: translateX(0px) translateY(0%) translateZ(0px);
        }
    }

    .white-bar {
        background: #ffffff;
        color: $blue;
        @media (max-width: 767px) {

            background: #f9f9f9;
            border-top: 1px solid #e4e4e4;
        }
        text-transform: uppercase;
        font-size: 12px;
        letter-spacing: 2px;
        font-weight: bold;
        text-align: center;

        .section {
            padding: 10px 30px;
            flex-basis: 33.333333%;
            font-size: 14px;
            line-height: 1.3;
            font-weight: 400;

            
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media(max-width: 991px){
                display: block;
                text-align: center;
            }
            @media(max-width: 767px){
                
                padding: 10px 0;
            }
           
            .image {
                margin-right: 10px;
                width: 48px;
                height: 48px;
                flex-basis: 48px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-shrink: 0;
                flex-grow: 0;
                background: $orange;
                padding: 3px;
                border-radius: 50%;
                img{
                    width: 28px;
                }
                .iconify{
                    font-size: 25px;
                    color: $orange;
                    color: #fff;
                }

                @media(max-width: 991px){
                    margin-bottom: 10px;
                    margin-right: auto;
                    margin-left: auto;
                }
               



            }

        }

        @media (max-width: 380px) {

            letter-spacing: 0px;
        }

    }



    .sticky-mega-menu {

        position: fixed;
        top: 50px;
        left: 0;
        width: 100%;
        background: rgb(255, 255, 255);
        border-top: 0px solid $purple;
        border-bottom: 10px solid $purple;
        box-shadow: inset 0 7px 9px -7px rgba(0, 0, 0, 0.4);
        -webkit-box-shadow: inset 0 7px 9px -7px rgba(0, 0, 0, 0.4);
    }


}
@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,900');

@font-face {
    font-family: 'Avenir';
    src: url('fonts/AvenirLTStd-Light.eot');
    src: url('fonts/AvenirLTStd-Light.eot?#iefix') format('embedded-opentype'),
        url('fonts/AvenirLTStd-Light.woff2') format('woff2'),
        url('fonts/AvenirLTStd-Light.woff') format('woff'),
        url('fonts/AvenirLTStd-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir';
    src: url('fonts/AvenirLTStd-Heavy.eot');
    src: url('fonts/AvenirLTStd-Heavy.eot?#iefix') format('embedded-opentype'),
        url('fonts/AvenirLTStd-Heavy.woff2') format('woff2'),
        url('fonts/AvenirLTStd-Heavy.woff') format('woff'),
        url('fonts/AvenirLTStd-Heavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir';
    src: url('fonts/AvenirLTStd-Black.eot');
    src: url('fonts/AvenirLTStd-Black.eot?#iefix') format('embedded-opentype'),
        url('fonts/AvenirLTStd-Black.woff2') format('woff2'),
        url('fonts/AvenirLTStd-Black.woff') format('woff'),
        url('fonts/AvenirLTStd-Black.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

$black: #1e1e23;
$red: #ab0a35;
$gray: #5a5a5e;

$lightblue: #1983c5; 

$purple: #003366;
$darkpurple: #4c3576;

$green: #4abdcf;

$darkblue: #2a8c9b;
$blue: #003366;

$orange: #f29a2e;
.school-category{
    margin-bottom: 30px;
    flex-basis: 100%;
    max-width: 100%;
    h2{
        letter-spacing: 0px;
        font-size: 15px;
        display: block;
    }
    .image{
        padding: 10px;
    }
    
    &:hover,&:focus{
        text-decoration: none;
        h2{
            background: darken($purple, 10%);
        }
    }
}
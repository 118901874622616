// compileCompressed : ../stylesheet.css
@import '_fonts';
@import '_colours';
@import '_header';
@import '_footer';
@import '_home';
@import '_find_your_school';
@import '_category';
@import '_product';
@import '_contact';
@import '_blog';


body{

    font-family: 'Lato';
    color: #666666;
    font-size: 15px;
    font-family: Lato;

    line-height: 1.2;
    
    .container-xl{
            max-width: 1340px;
            margin-right: auto;
            margin-left: auto;
            overflow: hidden;
    
    }

    @media (min-width: 768px){
        .modal-dialog {
            width: 650px;
            margin: 30px auto;
            min-height: calc(80% - 60px);
            display: flex;
            align-items: center;
            .modal-content{
                flex-grow: 1;
            }
        }

        #size-guide, #embroidery-help-modal{
            .modal-dialog {
                width: 750px;
            }
        }
    }

    #embroidery_warning{

        .modal-header{
            display: flex;
            justify-content: space-between;
            background: $green;
            h3{
                margin-right: 40px;
                color: #fff;
            }
        }

        .modal-body{
            h3{
                color: #003366;
                margin: 0;
                margin-bottom: 20px;
            }
        }
        .modal-footer{
            text-align: center;
            background: #f4f4f4;
            .btn{
                border-radius: 0px;
                font-size: 16px;
                margin: 5px;
                white-space: normal;
                &.btn-primary{
                    background: $green;
                    border: 1px solid darken($green, 10%);

                    &:hover, &:focus{
                        background: darken($green, 10%);
                    }
                }
            }
        }

    }

    #size-guide{
        border-radius: 0;;
        .modal-dialog{
            border-radius: 0;
            .modal-content{
                border-radius: 0;;
            }
        }
        .modal-header{
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: $blue;
            border-radius: 0;;
            h3{
                margin-right: 40px;
                color: #fff;
            }
        }

        

    }

    #home-modal{
        border-radius: 0;
        .modal-content{
            border-radius: 0;
            border: 10px solid #fff;

            .modal-header{
                background: $blue;
                h5{
                    color: #fff;
                    font-size: 30px;
                }
            }

            .modal-body{
                padding: 20px 20px 0px;
                line-height: 1.5;
            }
        }
    }

    .buttons {
        margin-top: 20px;
        .btn-primary {
            padding: 10px 30px;
            color: #fff;
            text-align: center;
            background: #fff;
            letter-spacing: 3px;
            border-radius: 0px;
            margin: 10px 0;
            display: inline-block;
            font-weight: bold;
            font-size: 14px;
            background: $purple;
            border-color: darken($purple, 10%);
            &:hover, &:focus{
                background: darken($purple, 10%);
                text-decoration: none;
            }
        }
        .btn-default {
            padding: 10px 30px;
            color: #666;
            text-align: center;
            background: #fff;
            letter-spacing: 3px;
            border-radius: 0px;
            margin: 10px 0;
            display: inline-block;
            font-weight: bold;
            font-size: 14px;
            background: #fff;
            border-color: darken(#fff, 20%);
            &:hover, &:focus{
                background: darken(#fff, 10%);
                text-decoration: none;
            }
        }


    }

    .filter-row{
        padding: 20px 0 5px;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        margin-bottom: 30px;
        .form-group{
        .input-group-addon{
            background: #fff;
            border: 0px;
            font-size: 15px;
            font-weight: bold;
        }
        select {
            cursor: pointer;
            width: 100%;
            background-color: #fff;
            border: 1px solid #ccc;
            border-radius: 0px;
            display: inline-block;
            font: inherit;
            line-height: 1.5em;
            padding: 0.5em 3.5em 0.5em 1em;
            margin: 0;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            -webkit-appearance: none;
            -moz-appearance: none;
            background-position: 100%;
            background-image: url(/image/selectarrow.png);
            background-size: auto;
            background-repeat: no-repeat;
            border-radius: 0 !important;
            height: 39px;
            font-size: 15px;
            &:focus {
                box-shadow: none;
                background-image: url(/image/selectarrowrev.png);
            }
        }
    }
    }


    #rating-section{


            background:#4c3576;
            border: 5px solid  #7653b7;
            margin: 20px 0px;
            padding: 20px 30px;
            color: #fff;


        #review{
            width: 100%;

            h3{
                margin-bottom: 20px;
            }

            textarea{
                height: auto;
                margin-bottom: 20px;
                font-size: 18px;
                border: 5px solid #ccc;
                padding: 15px;
                font-weight: normal;
            }

            .starrr{
                color: #fff;

            }
        }

    }





    a{
        color: $orange;


        &:hover, &:focus{
            color: $darkblue;
            text-decoration: none;
        }
    }

    .form-control{
        border-radius: 0px;
        height: 40px;
    }

    #account-account{
        h2{
            display: block;
            width: 100%;
            padding: 0;
            margin-bottom: 10px;
            padding-bottom: 10px;
            font-size: 21px;
            line-height: inherit;
            color: #4abdcf;
            border: 0;
            border-bottom: 1px solid #e5e5e5;
            font-family: 'Avenir';
            font-size: 35px;
        }
        ul{
            margin-bottom: 40px;
            li{
                a{
                    color: #666;
                    display: block;
                    margin-bottom: 7px;
                    &:hover, &:focus{
                        color: $blue;
                    }
                }
            }
        }
    }

    legend {
        display: block;
        width: 100%;
        padding: 0;
        font-weight: 300;
        margin-bottom: 20px;
        padding-bottom: 10px;
        font-size: 25px;
        line-height: inherit;
        color: #4abdcf;
        border: 0;
        border-bottom: 1px solid #e5e5e5;
        font-family: 'Avenir';

    }

    .alert-success{
        margin-bottom: 40px;
        border-radius: 0;
    }
    .alert-danger {

        //margin-top: -20px;
        border-radius: 0;
    }

    .panel-group .panel{
        border-radius: 0;
    }
    #button-search{
        padding: 10px 30px;
                color: #fff;
                text-align: center;
                background: #fff;
                letter-spacing: 3px;
                border-radius: 0px;
                margin: 10px 0;
                display: inline-block;
                font-weight: bold;
                font-size: 14px;
                background: $purple;
                border-color: darken($purple, 10%);
                &:hover, &:focus{
                    background: darken($purple, 10%);
                    text-decoration: none;
                }

    }

    .well{
        border-radius: 0;
        .btn-primary{
            padding: 10px 30px;
                color: #fff;
                text-align: center;
                background: #fff;
                letter-spacing: 3px;
                border-radius: 0px;
                margin: 10px 0;
                display: inline-block;
                font-weight: bold;
                font-size: 14px;
                background: $purple;
                border-color: darken($purple, 10%);
                &:hover, &:focus{
                    background: darken($purple, 10%);
                    text-decoration: none;
                }
        }
    }



}

.row{
    &:before, &:after{
        display: flex;
    }
}

body.iosBugFixCaret.modal-open { position: fixed; width: 100%; }

.breadcrumb-container{
    border-bottom: 1px solid #ececec;
    margin-bottom: 40px;
    @media (max-width: 767px) {
        margin-bottom: 20px;
    }
    .breadcrumb{
        margin-bottom: 0;
        background: none;
        margin-top: 0;
        border-radius: 0;
        padding: 10px 0;

        a{
            color: #666;
            font-size: 12px;
            letter-spacing: 1px;
            font-weight: bold;
            &:hover, &:focus{
                color: $green;
            }
        }

        li
        {
            &:last-child{
                a{
                    color: #4bbdcf;
                    &:hover, &:focus{
                        color: $green;
                    }
                }
            }
        }
    }
}



img{
    max-width: 100%;
}

.transition{
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

a{
    text-decoration: none;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.font-vag{
    font-family: 'Avenir';
}

.font-lato{
    font-family: 'Lato';
}

.p30{
    padding-top: 30px;
    padding-bottom: 30px;
    overflow: hidden;

    img{
        opacity: 0;
    }
}


.font-black{
    font-weight: 700;
}

.font-heavy{
    font-weight: 900;
}

.font-normal{
    font-weight: 400;
}

.font-light{
    font-weight: 300;
}

.font-thin{
    font-weight: 100;
}

.font-green{
    color: $green;
}

.font-bold{
    font-weight: bold;
}

.text-uppercase{
    text-transform: uppercase;
}

.bg-blue{
    background: $blue;
}

.bg-lightblue{
    background: $lightblue;
}

.section{

    padding: 30px 0;

    @media (max-width: 767px) {
        padding: 0;
    }

    .section-header{
        .section-header-title{
            font-size: 40px;
            margin: 20px auto 40px;
            text-align: center;
        }
    }

}


.full-button{

    padding: 10px 20px;
    color: #fff;
    text-align: center;
    background: #fff;
    font-size: 16px;
    letter-spacing: 2px;
    display: inline-block;
    margin: 10px 0;
    font-weight: normal;
    &.no-mt{
        margin-top: 0;
    }

    &:hover, &:focus{
        text-decoration: none;
        color: #fff;
    }

    &.green{
        background: $green;
        &:hover, &:focus{
            background: darken($green,10%);
        }
    }
    &.purple{
        background: $purple;
        &:hover, &:focus{
            background: darken($purple,10%);
        }
    }

    &.orange{
        background: $orange;
        &:hover, &:focus{
            background: darken($orange,10%);
        }
    }

}

.border-button{

    padding: 7px 15px;
    color: #666;
    text-align: center;
    background: #fff;
    font-size: 16px;
    letter-spacing: 1px;

    display: inline-block;
    &.no-mt{
        margin-top: 0;
    }

    &:hover, &:focus{
        text-decoration: none;
        color: #999;
        border: 3px solid #999!important;
    }

    &.white{
        &:hover, &:focus{
            text-decoration: none;
            color: #fff;
            background: none;
            border: 3px solid #fff!important;
        }
    }


    &.blue{
        border: 3px solid #2c91a3;
    }

    &.purple{
        border: 3px solid #4c3576;
    }

}

.menu-shadow {
    display: block;
    height: 16px;
    position: relative;
    z-index: 2;
    background-image: url(/image/menushadow.png);
    background-repeat: repeat-x;
}

.logo-line{
    line-height: 0.5;
    text-align: center;
    overflow: hidden;
    margin: 20px 0;

    .image {
        display: inline-block;
        position: relative;

        &:before, &:after{
            content: "";
            position: absolute;
            height: 1px;
            background-image: url('/image/stitch-blue.png');
            background-repeat: repeat-x;
            background-size: auto;
            top: 50%;
            width: 1000px;
        }

        &:before{
            right: 100%;
            margin-right: 15px;
        }
        &:after{
            left: 100%;
            margin-left: 15px;
        }
    }

}

.dashed-heading{
    line-height: 1;
    text-align: center;
    overflow: hidden;
    margin: 20px 0;
    > .h{
        display: inline-block;
        position: relative;
        margin: 0;
        font-size: 50px;
        &:before, &:after{
            content: "";
            position: absolute;
            height: 1px;
            background-image: url('/image/stitch-blue.png');
            background-repeat: repeat-x;
            background-size: auto;
            top: 50%;
            width: 1000px;
        }

        &:before{
            right: 100%;
            margin-right: 15px;
        }
        &:after{
            left: 100%;
            margin-left: 15px;
        }
        @media (max-width: 1199px) {
           font-size: 45px;
        }
        @media (max-width: 991px) {
           font-size: 40px;
        }

         @media (max-width: 767px) {
           font-size: 35px;
        }
        @media (max-width: 575px) {
           font-size: 30px;
        }
    }

}

#product-manufacturer{
    .row{
        @media (max-width: 575px) {
            margin-left: -8px;
            margin-right: -8px;
        }
    }

    .filter-row{
        padding: 20px 0 5px;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        margin-bottom: 30px;
    }
    .form-group{
        .input-group-addon{
            background: #fff;
            border: 0px;
            font-size: 15px;
            font-weight: bold;
        }
        select {
            cursor: pointer;
            width: 100%;
            background-color: #fff;
            border: 1px solid #ccc;
            border-radius: 0px;
            display: inline-block;
            font: inherit;
            line-height: 1.5em;
            padding: 0.5em 3.5em 0.5em 1em;
            margin: 0;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            -webkit-appearance: none;
            -moz-appearance: none;
            background-position: 100%;
            background-image: url(/image/selectarrow.png);
            background-size: auto;
            background-repeat: no-repeat;
            border-radius: 0 !important;
            height: 39px;
            font-size: 15px;
            &:focus {
                box-shadow: none;
                background-image: url(/image/selectarrowrev.png);
            }
            -webkit-appearance: none;
            -moz-appearance: none;
            -ms-appearance: none;
            -o-appearance: none;
            appearance: none;
            -moz-appearance: none;
            appearance: none;

            text-overflow: ''; /* this is important! */

            @media (max-width: 767px) {
                font-size: 16px;

            }
        }

        select.form-control::-ms-expand {
            display: none;
        }

    }

    .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto{

        @media (max-width: 575px) {
            padding-left: 8px;
            padding-right: 8px;
        }

    }
    .block{
        h2{
            background: $green;
            padding: 5px 10px;
            color: #fff;
            margin-bottom: 20px;
            font-size:19px;
        }

        a{
            flex-grow: 1;
            flex-basis: 100%;
            max-width: 100%;

            margin-bottom: 30px;
            @media (max-width: 575px) {
                margin-bottom: 16px;

            }
            border: 1px solid #ccc;
            &:hover, &:focus{
                border: 1px solid $green;
            }
            >div {
                padding: 20px;
                flex-basis: 100%;
                max-width: 100%;
                text-align: center;
                @media (max-width: 575px) {
                    padding: 10px;

                }
                img{
                    width: auto;
                    max-width: 100%;
                }
            }


        }
    }
}


.page-header{

    position: relative;
    z-index: 1;
    margin: 0;
    padding: 50px 0;

    border: 0px;
    &.product-header{
        //min-height: 350px;
        @media (max-width: 991px) {
            min-height: auto;

        }
    }

    @media (max-width: 767px) {
        padding: 25px 0;
        text-align: center;
    }


    background-size: auto;
    background-position: right top;
    background-repeat: no-repeat;


    background-color: $blue;
    background-image: url('/image/header-bg.jpg');

    color: #fff;


    h1, .h1{
        font-size: 55px;
        display: block;
        margin:0;
        line-height: 1.1;
        letter-spacing: 2px;
        @media (max-width: 1199px) {
            font-size: 50px;
        }
        @media (max-width: 991px) {
            font-size: 40px;
        }
        @media (max-width: 767px) {
            font-size: 30px;

        }

    }

    &.product{
        h1, .h1{
        font-size: 40px;

       
        @media (max-width: 991px) {
            font-size: 35px;
        }
        @media (max-width: 767px) {
            font-size: 30px;

        }
    }

    }

    .border-down{
        background-image: url('/image/stitch.png');
        background-repeat: repeat-x;
        display: block;
        position: absolute;
        bottom: 10px;
        left: 0;
        width: 100%;
        height: 1px;
        z-index: 10;
    }
    .border-up{
        background-image: url('/image/stitch.png');
        background-repeat: repeat-x;
        display: block;
        position: absolute;
        top: 10px;
        left: 0;
        width: 100%;
        height: 1px;
        z-index: 10;
    }

}



.pagination{
    > li{
        border: 0px!important;
        > a, > span{
            border-radius: 0px;
            color: #666;
            font-weight: bold;
            &:hover, &:focus{
                color: $green;
            }
        }
        span{
        }
        &:first-child, &:last-child{
            > a, >span{
                border-radius: 0px;
            }
        }

        &.active{
            > a, > span{
                border-color: #ddd;
                color: #fff;
                background: $blue;
                &:hover, &:focus{
                    color: #fff;
                    background: $darkblue;
                }
            }
        }
    }
}

.gradient-section{
    background-image: url('/image/blue-gradient.jpg');
    background-repeat:repeat-x;
    background-size: auto;
    background-position: top left;
}

.white-section{
    background: #fff;
    padding: 20px 25px;
    padding-bottom: 50px;
    @media (max-width: 767px) {
        padding: 20px;
        padding-bottom: 0;

    }

}

.category-logo{

    margin-bottom: 20px;
}


.why-loop{
    background: #021f3b;
    margin-bottom: 0;
    margin-top: 0;
    .inner{
        background-size: auto 100%;
        background-position: top right;
        position: relative;
        padding: 50px 0;

        .container{
            position: relative;
        }

    }
    .border-down{
        background-image: url('/image/stitch.png');
        background-repeat: repeat-x;
        display: block;
        position: absolute;
        bottom: 10px;
        left: 0;
        width: 100%;
        height: 1px;
        z-index: 10;
    }
    .border-up{
        background-image: url('/image/stitch.png');
        background-repeat: repeat-x;
        display: block;
        position: absolute;
        top: 10px;
        left: 0;
        width: 100%;
        height: 1px;
        z-index: 10;
    }
    .why-image{
        height: 100%;
        width: auto;
        position: absolute;
        right: 0;
        top: 0;
    }


    .text{
        color: #fff;
        p{
            font-size: 16px;
            line-height: 1.5;
            @media (max-width: 991px) {
                font-size: 16px;
            }
            @media (max-width: 767px) {
                font-size: 15px;

            }
        }
        h4{
            font-size: 50px;
            @media (max-width: 991px) {
                font-size: 40px;
            }
            @media (max-width: 767px) {
                font-size: 30px;

            }
        }
        .full-button{
            font-weight: 900;
            font-size: 12px;
            margin-top: 10px;
        }
    }


}

.blue-box{
    background: $blue;
    padding: 10px;
    .inner{
        color: #fff;
        border: 2px solid rgba(255, 255, 255, 0.4);
        padding: 40px;
        font-size: 21px;
        line-height: 1.4;
        font-weight: 300;
        @media (max-width: 767px) {
            font-size: 18px;
            padding: 30px;
        }
        @media (max-width: 575px) {
            font-size: 16px;
            padding: 20px;
        }
    }
}

.image-box{
    background-size: cover;
    background-position: 50% 50%;
    flex-basis: 100%;
    max-width: 100%;
    min-height: 350px;
    @media (max-width: 767px) {
        min-height: 300px;

    }
}

#column-left{

    .image{
        background-size: cover;
        background-position: 50% 50%;
        height: 250px;
        margin-bottom: 20px;
    }

    .box{
        margin: 0;
        margin-bottom: 20px;
        @media (max-width: 767px) {
            margin-bottom: 0;
            margin-top: 20px;
        }
    }

    .panel{
        border-radius: 0px;
        border: 0px;
        box-shadow: none;
        .panel-heading{
            background: $darkpurple;
            color: #fff;
            font-size: 19px;
            border-radius: 0;
            border: 0px;
            margin-bottom: 20px;
            letter-spacing: 1px;


        }

        .list-group{
            a.list-group-item{
                display: none;
            }

            div.list-group-item{
                border: 1px solid #ccc;
                margin-bottom: 20px;
            }
        }

        .panel-footer{
            background: none;
            padding: 0;
            .full-button{
                border: 0px;
                box-shadow: none;
                margin: 0;
                letter-spacing: 0;
                font-weight: normal;

            }
        }
    }
}

.page{

    .h2{
        font-size: 35px;
        color: $blue;
        padding-bottom: 20px;
        margin-top: 0;
        margin-bottom: 20px;
        border-bottom: 1px solid $darkblue;
        @media (max-width: 991px) {
            font-size: 30px;
        }
        @media (max-width: 767px) {
            font-size: 25px;

        }

    }

    .box{
        padding: 8px;
        flex-basis: 100%;
        max-width: 100%;
        margin-bottom: 15px;
        display: flex;
        align-items: stretch;

        @media (max-width: 991px) {
            margin: 10px;
            margin-top: 30px;
            margin-bottom: 0px;
            &:nth-child(1){
                margin-left: 0;
            }
            &:nth-child(2){
                margin-right: 0;
            }
        }

        &.purple{
            background: $purple;

            &:hover, &:focus{
                background: darken($purple, 10%);
            }
        }

        &.blue{
            background: $blue;
            &:hover, &:focus{
                background: darken($blue, 10%);
            }
        }

        &.stone{
            background-image: url('/image/stone.png');
            background-size: cover;
            background-position: 50% 50%;
        }
        .inner{
            border: 2px solid rgba(255,255,255,0.4);
            background-position: bottom right;
            background-repeat: no-repeat;

            @media (max-width: 991px) {
                background-size: auto 200px;
            }

            flex-basis: 100%;
            max-width: 100%;
            padding: 15px;
            color: #fff;
            h4{
                font-size: 22px;
                margin: 0;


            }
            p{
                font-size: 14px;
                margin-bottom: 0;
            }

            img{
                margin-left: 15px;
            }


        }
    }

    #content{
        line-height: 25px;
    }

}


#accordion{
    background: #fff;

    .card{
        margin-bottom: 3px;

        .card-header{

            h5{
                margin: 0;
                button{
                    display: block;
                    color: $blue;
                    font-size: 19px;
                    @media (max-width: 767px) {
                        font-size: 16px;
                    }
                    padding: 15px ;
                    width: 100%;
                    text-align: left;
                    outline: none;
                    position: relative;
                    box-shadow: none;
                    border: 0;
                    white-space: normal;
                    &:hover, &:focus{
                        text-decoration: none;
                        outline: none;
                        box-shadow: none;
                    }
                    border-bottom: 1px solid #ccc;

                    padding-right: 50px;
                    padding-left: 0;

                    &:after{
                        position: absolute;
                        right: 0;
                        top: 50%;
                        width: 30px;
                        height: 1px;
                        background: #666;
                        content: '';
                        -webkit-transition: all 0.3s;
                        -moz-transition: all 0.3s;
                        -o-transition: all 0.3s;
                        transition: all 0.3s;
                    }
                    &:before{
                        opacity: 0;
                    }

                    &.collapsed{

                        color: #666;

                        &:hover{
                            color: $blue;
                        }

                        &:after{
                            background: $blue;
                        }
                        &:before{
                            position: absolute;
                            right: 15px;
                            top: calc(50% - 15px);
                            width: 1px;
                            height: 30px;
                            opacity: 1;
                            background: $blue;
                            content: '';
                            -webkit-transition: all 0.3s;
                            -moz-transition: all 0.3s;
                            -o-transition: all 0.3s;
                            transition: all 0.3s;

                        }
                    }



                }

            }
        }

        .card-body{
            padding: 20px 0;
        }
    }
}

#page-size-guide{

    .table-responsive{
        margin-top: 30px;
        margin-bottom: 20px;
    }

    .vertical-table{
        border: 1px solid #fff;
        background: #dddddd;
        margin-bottom: 0;

        thead{
            tr{
                th{
                    border: 1px solid #fff;
                    text-align: center;
                    vertical-align: middle;
                    padding: 10px 20px;
                    width: 12%;
                }

                &:first-child{
                    th{
                        background: $darkpurple;
                        color: #fff;
                    }
                }
                &:last-child{
                    th{
                        background: #999;
                        color: #fff;
                    }
                }
            }

        }
        tbody{
            tr{
                td{
                    width: 12%;
                    border: 1px solid #fff;
                    text-align: center;
                    vertical-align: middle;
                    color: #333;
                    background: #dddddd;
                    padding: 8px 10px;
                }

                &:hover{
                    td{
                        background: #ccc;

                    }

                }
            }

        }
    }

    .horizontal-table{
        border: 1px solid #fff;
        background: #dddddd;
        margin-bottom: 0;
        &.size-14{
            tbody{
                tr{
                    td{
                        width: 6.9230%;
                        &:first-child{
                            width: 10%;
                        }
                    }
                }
            }

        }

        &.size-8{
            tbody{
                tr{
                    td{
                        width: 14%;
                        &:first-child{
                            width: 16%;
                        }
                    }
                }
            }
        }


        tbody{
            tr{
                td{
                    width: 12%;
                    border: 1px solid #fff;
                    text-align: center;
                    vertical-align: middle;
                    color: #333;
                    padding: 8px 10px;
                    background: #dddddd;
                    &:first-child{
                        background: $darkpurple;
                        color: #fff;
                    }
                }

                &:hover{
                    background: #ccc;
                    td{
                        background: #ccc;
                        &:first-child{
                            background: $darkpurple;
                            color: #fff;
                        }
                    }
                }
            }

        }
    }


}

#page-register-school{


    .top-info{
        padding-bottom: 30px;
        margin-bottom: 20px;
        border-bottom: 1px solid #2a8c9b;
    }

    #register-school-form{

        legend{
            margin-bottom: 5px;
            border: 0px;
            font-size: 16px;
            font-weight: bold;
            color: #666;
            padding-bottom: 0;
            font-family: 'Lato';
        }
        .form-group{
            margin-bottom: 10px;
        }

        textarea{
            border-radius: 0px;
            border: 3px solid #ccc;
            font-size: 16px;
        }

        input.form-control{
            border-radius: 0px;
            border: 3px solid #ccc;
            font-size: 16px;
            height: 45px;

        }



        .error{
            margin-top: 20px;
            margin-bottom: 10px;
            display: none;
        }

        em{
            display: block;
            color: #666;
        }

        .success{
            color: $darkblue;
        }

    }


}

#checkout-cart{
    .form-control{
            height: 34px;
    }
}


.list-group.manu{
    border-radius: 0px;
    border: 0px;
    a{
        border-radius: 0px;
        border: 0px;
        border-bottom: 1px solid #fff;
        background: #c2c2c2;
        letter-spacing: 2px;
        font-weight: 300;
        font-size: 14px;
        color: #fff;
        margin-bottom: 0px;
        &:hover, &:focus, &.active{
            background: $blue;
        }
    }
    
    .refine{
        margin-bottom: 15px;
        h3{
            background: $blue; 
            padding: 10px 15px;
                font-size: 19px;
                color: #fff;
                margin-bottom: 1px;
                margin-top: 0;
        }
        h4{
            background: #33cccc; 
            padding: 10px 15px;
                font-size: 19px;
                color: #fff;
                margin-bottom: 10px;
                margin-top: 0;
        }
        a{
            border-radius: 0px;
            border: 0px;
            letter-spacing: 0px;
             padding: 10px 15px;
            border-bottom: 0px solid #fff;
            background: #fff;
            font-weight: 300;
            font-size: 15px;
            color: #666;
            margin-bottom: 0px;
            &:hover, &:focus{ 
                color: #33cccc;
            }
            &.active{
                font-weight: bold;
            }
            
        }
    }
}


#page-contact{
    
    
    .top-info{
        padding-bottom: 30px;
        margin-bottom: 20px;
        border-bottom: 1px solid $darkblue;
    }
   
    
    .map{
        iframe{
                margin-bottom: 20px;
                width: 100%;
                @media (max-width: 767px) {

                    margin-bottom: 0px;
                    margin-top: 20px;
                }
        }
    }

    #enquiry-form{
        
        legend{
            margin-bottom: 5px;
            border: 0px;
            font-size: 16px;
            font-weight: bold;
            color: #666;
                    padding-bottom: 0;
                    font-family: 'Lato';
        }
        .form-group{
            margin-bottom: 10px;
        }
        
                textarea{
                    border-radius: 0px;
                    border: 3px solid #ccc;
                    font-size: 16px;
                    height: auto;
                }

                input.form-control{
                    border-radius: 0px;
                    border: 3px solid #ccc;
                    font-size: 16px;
                    height: 45px;
                    
                }
                
              
        
        .error{
            margin-top: 20px;
            margin-bottom: 10px;
            display: none;
        }
        
        em{
            display: block;
            color: #666;
        }
        
        .success{
            color: $darkblue;
        }

    }
    
    

}


#common-home{
    position: relative;
}

.white-section-home{
    
    padding-top: 40px;
    .inner{

        .full-button{
            font-weight: 600;
            font-size: 14px;
        }
        font-size: 16px;
        line-height: 1.5;
        @media (max-width: 767px){
            font-size: 15px;
            line-height: 1.3;
        }
        h4{
            font-size: 35px;
            color: #005387;
            margin-top: 0;
            @media (max-width: 767px){
                font-size: 30px;
            }
            @media (max-width: 575px){
                font-size: 26px;
            }

        }

        padding: 30px;
        &.border{
            border: 5px solid #e7e7e7;
        }
        margin-bottom: 30px;

        .manu{

            flex-basis: 33.3333%;
            max-width: 33.33333%;
            flex-grow: 1;

            @media (max-width: 991px) and (min-width: 768px){
                flex-basis: 50%;
                max-width: 50%;
            }
            @media (max-width: 490px){
                flex-basis: 50%;
                max-width: 50%;
            }
            a{
                border: 1px solid #cccccc;
                padding: 15px;
                flex-grow: 1;
                margin-bottom: 10px;
                background: #fff;
                img{
                    width: 100%;
                    flex-grow: 0;
                    flex-shrink: 1;
                }
                &:hover, &:focus{
                    background: #f4f4f4;
                }
            }
            
        }
    }
}

.featured-products{
    h3{
        font-size: 40px;
        margin: 20px auto 40px;
        text-align: center;
    }

    .product-thumb .price{
        background: #fff;;
    }
}

.loading{
    background: #ffffff; 
    padding: 100px 0;
    position: absolute;
    top:0;
    width: 100%;
    left:0;
    z-index: 2;
    //height: 429px;
    opacity:0;
    visibility: hidden;

    &.hide{

        opacity: 0;
        visibility: hidden;


    }

    @media (max-width: 1199px) {
        height: 400px;
    }
    @media (max-width: 991px) {
        height: 300px;
    }
    @media (max-width: 767px) {
        display: none!important;
    }
    #loader{
        position: absolute;
        left: 50%;
        top: 50%;
        z-index: 1;
        width: 150px;
        height: 150px;
        margin: -75px 0 0 -75px;
        border: 16px solid #f3f3f3;
        border-radius: 50%;
        border-top: 16px solid $green;
        width: 120px;
        height: 120px;
        -webkit-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;
    }
    @-webkit-keyframes spin {
        0% {
            -webkit-transform: rotate(0deg); }
        100% {
            -webkit-transform: rotate(360deg); } }
    @keyframes spin {
        0% {
            transform: rotate(0deg); }
        100% {
            transform: rotate(360deg); } }
}    


#home-slider{
    position: relative;
    z-index: 1;
    padding: 0;
    margin-bottom: 0;
    background: #ffffff;
    //height: 429px;
    @media (max-width: 1199px) {
        //height: 400px;
        //margin-bottom: 40px;
    }
    @media (max-width: 991px) {
        //height: 300px;
        //margin-bottom: 30px;
    }
    @media (max-width: 575px) {
         //margin-bottom: 30px;
                height: auto;
            }
    

    .slides{
        //height: 429px;

        max-width: 1340px;
        margin: 0 auto;
        
        z-index: 5;
        
        width: 100%;
        @media (max-width: 1199px) {
            //height: 400px;
        }
        @media (max-width: 991px) {
            //height: 300px;
        }
        @media (max-width: 575px) {
                height: auto;
            }
        

            

        .item{
            img{
                border: 0px solid #e4e4e4;
                margin:20px 0;
                @media (max-width: 767px){
                    margin:0px 0;
                    border: 0px solid #e4e4e4;
                }
            }
            
            .container{
                @media (max-width: 575px) {
                    position: relative;
                    box-shadow: 0px 3px 24px 4px rgba(0, 0, 0, 0.73);
                    z-index: 9;
                    overflow: hidden;
                   
                }
            }
            
            &.purple{
                background: $blue;
                @media (max-width: 575px) {
                .container{
                    background-image: url('/image/white-l.png');
                    background-size: auto 100%;
                    background-position: top center;
                    background-repeat: no-repeat;
                }
                }
            }
            
            &.blue{
                background: #3895a4;
                @media (max-width: 575px) {
                .container{
                    background-image: url('/image/white-l-blue.png');
                    background-size: auto 100%;
                    background-position: top center;
                    background-repeat: no-repeat;
                }
                }
            }
            
            //height: 429px;
            position: relative;
            @media (max-width: 1199px) {
                //height: 400px;
            }
            @media (max-width: 991px) {
                //height: 300px;
            }
            @media (max-width: 575px) {
                height: auto;
                overflow: hidden;
            }


            .main-image{
                height: 100%;
                width: 100%;
                background-size: auto 100% ;
                background-position: center center;
                 background-repeat: no-repeat;
                position: absolute;
                right: 0;
                top:0;
                @media (max-width: 575px) {
                    position: relative;
                    right: auto;
                    top:auto;
                    height: 200px;
                    width: 100%;
                    background-size: cover;
                    background-position: 50% 50%;
                }

            }

            .l-image{
                height: 100%;
                width: 100%;
                position: absolute;
                left: 0px;
                top:0;
                flex-basis:100%;
                max-width: 100%;
                z-index: 0;
                
            }

            .slide-text{
                img{
                    width: auto;
                    margin: 0 auto;
                    margin-bottom: 20px;
                }
                height: 429px;
                position: relative;
                z-index: 2;
                padding: 0 30px;
                flex-basis:100%;
                max-width: 100%;
                @media (max-width: 1199px) {
                    height: 400px;
                }
                @media (max-width: 991px) {
                    height: 300px;
                    padding: 0 20px; 
                }
                @media (max-width: 575px) {
                    height: auto;
                    padding: 50px 0 70px;
                    text-align: center;
                    justify-content: center;
                }
                

                h3{
                    font-size: 50px;
                    color: #fff;
                    margin-bottom: 5px;
                    margin-top:0;
                    line-height: 1;
                    @media (max-width: 1199px) {
                        font-size: 40px;
                    }
                    @media (max-width: 991px) {
                        font-size: 30px;
                    }
                }
                h2{
                    font-size: 60px;
                    color: $blue;
                    background: #fff;
                    border: 3px solid $orange;
                    margin-top: 0;
                    padding: 8px 30px;
                    line-height: 1;
                    @media (max-width: 1199px) {
                        font-size: 55px;
                    }
                    @media (max-width: 991px) {
                        font-size: 45px;
                    }
                }
                p{
                    font-size: 21px;
                    color: #fff;
                    @media (max-width: 991px) {
                        font-size: 18px;
                    }
                    @media (max-width: 767px) {
                        display: none;
                    }
                }

                .full-button{
                    padding: 10px 30px;
                    font-weight: 900;
                    letter-spacing: 1px;
                    
                    
                        font-size: 14px;
                  
                }
                
                


            }
            .border-up{
                background-image: url('/image/stitch.png');
                background-repeat: repeat-x;
                display: block; 
                position: absolute;
                bottom: 10px;
                left: 0;
                width: 100%;
                height: 1px;
                z-index: 10;
            }
            .border-down{
                background-image: url('/image/stitch.png');
                background-repeat: repeat-x;
                display: block; 
                position: absolute;
                top: 10px;
                left: 0;
                width: 100%;
                height: 1px;
                z-index: 10;
            }
        }

        .owl-nav{
            margin-top: 0;
        }
        
        .owl-prev, .owl-next{
            position: absolute;
            top: calc(50% - 45px);
            background: none;
            padding: 0;
            margin: 0;
            
        }
        
        .owl-prev{
            
            
            left: 20px;
        }
        
        .owl-next{
            
            right: 20px;
        }

        .owl-dots{
            
            margin-bottom: 0;
            position: realtive;
            bottom: 0;padding: 10px 0;background: #fff;
            
            margin: 0 auto;
            display: block;
            left: 0;
            right: 0;
            .owl-dot{
                span{
                    margin: 0 4px;
                    width:15px;
                    height: 15px;
                    border-radius: 50%;
                    background: #ccc;
                    //box-shadow: 0 0 5px rgba(0,0,0,0.6);
                }

                &.active{
                    span{
                        background:#f29a2e;
                    }  
                }
            }
        }

    }


}


#home-boxes{
    background: #fff;
    box-shadow: 0 5px 10px rgba(0,0,0,0.2);
    @media (max-width: 767px) {
        padding-top: 30px;
    }
    .box{
        //padding: 10px;
        flex-basis: 100%;
        max-width: 100%;
        margin-bottom: 30px;
       
        &.blue{
            background: $blue;
        }

       
        .inner{
            //border: 2px solid rgba(255,255,255,0.4);
            background-position: bottom right;
            background-repeat: no-repeat;

            @media (max-width: 991px) {
                background-size: auto 200px;
            }
            position: relative;

            flex-basis: 100%;
            max-width: 100%;
            padding: 0 15px;

            &:before{
                content: '';
                padding-top: 70%;
            }
            color: #fff;
            >div{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                flex-basis: 100%;
                max-width: 100%;
                >div{
                   max-width: 100%;
               }
            }
            h3{
                font-size: 45px;
                margin-top: 0;
                text-shadow: 0px 0px 10px #222;
                &.smaller{
                    font-size: 35px;
                }
                @media (max-width: 1199px) {
                    font-size: 35px;
                }
                @media (max-width: 991px) {
                    font-size: 30px;
                }
                @media (max-width: 767px) {
                    font-size: 30px;
                }
                @media (max-width: 575px) {
                    font-size: 25px;
                }

            }
            p{
                font-size: 16px;
                @media (max-width: 767px) {
                    font-size: 15px;
                }
            }

            .screen-align{
                @media (max-width: 991px) {
                    text-align: center;
                }
                @media (max-width: 767px) {
                    text-align: left;
                }
                @media (max-width: 575px) {
                    text-align: center;
                }
            }

            .right-image{
                margin-left: 15px;

                @media (max-width: 1199px) {
                    max-width: 140px;
                    flex-basis: 140px;
                }
                @media (max-width: 991px) {
                    margin-left: 0;
                    margin-bottom: 15px;
                }
                @media (max-width: 767px) {
                    margin-left: 15;
                    margin-bottom: 0;
                }
                @media (max-width: 575px) {
                    margin-left: 0;
                    margin-bottom: 15px;
                    max-width: 120px;
                    flex-basis: 120px;
                }
            }
            .left-image{
                margin-right: 15px;
                @media (max-width: 991px) {
                    margin-left: 0;
                    margin-bottom: 15px;
                }
                @media (max-width: 767px) {
                    margin-left: 15;
                    margin-bottom: 0;
                }
                @media (max-width: 575px) {
                    margin-left: 0;
                    margin-bottom: 15px;
                }
            }
            

            .full-button{
                margin-bottom: 20px;
                font-size: 14px;
                font-weight: 600;
            }
        }
    }
}

.get-in-touch-banner{
    padding: 80px 0;
    @media (max-width: 991px) {
        padding: 40px 0;
    }
    @media (max-width: 767px) {
        padding: 20px 0;
    }
    background-color: #efefef;
    background-image: url('/image/footer-bg.jpg');
    background-position: center bottom;
    background-size: auto 100%;
    background-repeat: no-repeat;
    .round-button{
        margin: 10px;

    }
}

#xmas-modal{

    .modal-content{
        border-radius: 0;
        background-image: url('/image/snowflakes.jpg');
        background-size: auto;
        background-position: 50%;
        padding: 50px; 
        border: 10px solid #fff;
        @media (max-width: 767px) {
            padding: 50px 20px; 
            border: 5px solid #fff;
        }
        .modal-header{
            padding: 0;
            min-height: 0;
            border: 0;
            margin: 0;
            .close{
                float: none;
                position: absolute;
                top: 0;
                margin-top: 0;
                right: 0;
                width: 45px;
                height: 45px;
                background: #ccc;
                opacity: 1;
                color: #fff;
                font-size: 44px;
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: center;
                span{
                    line-height: 40px;
                    height: 45px;
                    display: block;
                }
            }
        }
    
        .modal-body{
            background: #fff;
            border-radius: 0;
            padding: 40px;
            border: 5px solid #c9c9c9;
            font-size: 18px;
            text-align: center;
            color: #333;
            line-height: 1.5;
            box-shadow: 0 0 13px rgba(0,0,0,0.5);
            @media (max-width: 767px) {
                padding: 20px;
                font-size: 15px;
            }
            .red{
                color: #b00000;
                font-weight: bold;
                font-size: 20px;
                font-style: italic;
                @media (max-width: 767px) {
                    font-size: 18px;
                }
            }
            h3{
                margin: 0;
                .red{
                    font-size: 25px;
                }
            }
            p{
                margin-bottom: 0;
            }
    
        }
    }

    

}

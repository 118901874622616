// main : stylesheet.scss
.sidebar-product-info{
    margin-bottom: 50px;
    padding-right: 30px;
    @media (max-width:991px) {

        padding-right: 0;

    }
}
.product-thumb{
    border: 1px solid #ccc;
    padding: 12px;
    margin-bottom: 30px;
    flex-basis: 100%;
    max-width: 100%;
    background: #fff;
    .image{

        -ms-flex-negative: 0;
        img{
            margin: 0 auto;
        }
    }

    h4{
        a{
            font-size: 15px;
            display: block;
            letter-spacing: 1px;
            color: #333;
            line-height: 1.3;
            text-transform: uppercase;
            padding: 15px 10px;
            text-align: center;
            &:hover, &:focus{
                color: $blue;
            }
        }
        margin: 0;

    }

    .price{
        display: block;
        padding: 10px 20px;
        color: #666;
        background: #e7e7e7;
        text-align: center;
        font-weight: 900;
        letter-spacing: 1px;
        margin-bottom: 0;

        span{
            margin-right: 5px;
        }

        
    }

    .view-product{
        flex-basis: calc(100%);
        max-width: calc(100%);
        background: $blue;
        letter-spacing: 3px;
        text-align: center;
        padding: 10px 20px;
        color: #fff;

    }

    .cart-button{
        display: flex;
        align-items: center;
        justify-content: center;
        background: $darkblue;
        max-width: 40px;
        flex-basis: 40px;
        cursor: pointer;
        i{
            color: #fff;
            font-size: 20px;

        }
        &:hover, &:focus{
            background: darken($darkblue, 10%);
        }

    }

    &:hover, &:focus{
        border: 1px solid #aaa;
        .view-product{
            background: $orange;
        }
    }
}
#product-product{

    #extra-data-tabs{

        border-bottom: 1px solid #ddd;
        >li.active>a, >li.active>a:focus, >li.active>a:hover{

            background: #666666;
            border-radius: 0;
            color: #fff;
        }

        li{
            margin-bottom: 0;
            border-radius: 0;
            a{
                border-radius: 0;
                border-color: transparent;
                border-bottom: 0;
                color: #333;
            }
        }

    }

    #extra-data{

        .tab-pane{
            padding: 0;
            border: 1px solid #ddd;
            border-top: 0px;
            table{
                margin: 0;
            }
            .table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th{
                padding: 10px 15px;
            }
        }

        #features{
            ul{
                padding: 20px;
                margin: 0;
                li{
                    img{
                        margin: 10px
                    }
                }
            }
        }

    }

    #accordion .card .card-header h5 button{
        border-bottom: 1px solid #eaeaea;
    }
    #accordion .card .card-header h5 button.collapsed:after,
    #accordion .card .card-header h5 button.collapsed:before{
        background: $green;
    }

    #embroidery{

        border-radius: 0;

        .modal-dialog{
            width: 900px;
            position: relative;
        }

        .modal-content{
            border-radius: 0;

            .modal-body{
                padding: 5px;
            }
        }

        .fancy-banner-container{
            padding-bottom: 0;
            margin-bottom: 0;
            border: 0;
            .fancy-banner{
                .inner{
                    background: none;
                    padding: 50px 15px 50px 40px;
                    p{
                        font-size: 15px;
                    }
                }
            }
        }

        .close-button{
            cursor: pointer;
            width: 45px;
            height: 45px;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 0;
            right: 0;
            position: absolute;
            background: #fff;
            i{
                color: $blue;
                font-size: 30px;
            }

            &:hover{
                background: $blue;
                i{
                    color: #fff;
                }

            }
        }

    }

    padding-bottom: 50px;
    .thumbnails {
        overflow: auto;
        clear: both;
        list-style: none;
        padding: 0;
        margin: 0;
        margin-right: -10px;
        margin-left: -10px;
        .thumbnail{
            margin-right: 10px;
            margin-left: 10px;
            border-radius: 0px;
            padding: 0;
            border: 2px solid #e8e8e8;

            &:hover, &:focus{
                border: 2px solid #dca4b5;
            }
        }

        .image-additional{
            float: left;
            padding-right: 10px;
            padding-left: 10px;
            max-width: 95px;
            .thumbnail{
                margin-right: 0;
                margin-left: 0;

            }
            a{
                border-radius: 0px;
            }

        }

    }

    h1{
        font-size: 35px;
        margin-top: 0;
        line-height: 1.3;
        margin-bottom: 15px;
        padding-bottom: 15px;
        border-bottom: 1px solid #eaeaea;
        @media (max-width:991px) {

            font-size: 28px;

        }
        @media (max-width: 767px) {

            font-size: 22px;

        }
    }

    .price{
            color: $orange;
            font-size: 35px;
            margin-bottom: 15px;
            border-bottom: 1px solid #eaeaea;
            padding-bottom: 15px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            @media (max-width: 575px) {
                flex-direction: column;
                align-items: flex-start;
            }
        .chest-sticker{
            font-size: 18px;
            margin-left: 15px;
            color: #fff;
            border-radius: 30px;
            padding: 8px 16px;
            background-image: linear-gradient(-90deg, $green 0%,$orange 100%) !important;
            @media (max-width: 575px) {
                margin: 10px 0;
               
            }
    }
        }

    .gray-area, .white-area{
        //padding: 20px 20px;
        margin-bottom: 20px;
        @media (max-width: 767px) {

             padding: 15px 15px;

        }
        @media (max-width: 575px) {

             margin-left: -15px;
             margin-right: -15px;

        }
        .list-unstyled{
            margin-bottom: 0;
            li{
                line-height: 1.4;
            }
        }

        &.no-left-right{
            padding-left: 0;
            padding-right: 0;
        }


    }

    .gray-area{
 margin-bottom: 15px;
            border-bottom: 1px solid #eaeaea;
        padding-bottom: 15px;

    }

    .white-area{

        a{
            &:hover, &:focus{
                color: $blue;
            }
        }

        h5{
            margin-top:0;
            font-size: 15px;
        }

        &.manu{
            padding-bottom: 20px;
            padding-top: 15px;
        }

        .desc{
            ul{
                list-style: none;
                padding: 0;
                li{
                    line-height: 1.8;
                }
            }
        }

    }

    #button-cart, #button-cart2{
        font-size: 20px;
        text-transform: uppercase;
        font-weight: 300;
        border: 0px;
        box-shadow: none;
        text-align: center;
        padding: 7px 40px;
        line-height: 1;
        color: #fff;
        position: relative;
        letter-spacing: 2px;

        @media (max-width: 767px) {

            display: block;
            text-align: center;
            margin-right: 0;
            width: 100%;
            margin-top: 10px;

        }

    }

    .size-guide{
        color: $orange;
        cursor: pointer;
    }

    #button-cart2{
        @media (max-width: 767px) {

            margin-top: 0px;

        }
    }

    .main-thumb{
        @media (max-width: 767px) {
            img{
                max-width: 220px;
            }
        }
    }
    #product{

        input[type=radio]:checked + .img {

            position: relative;


            &:after{
                content:  '';
                position: absolute;
                z-index: 2;
                bottom:-5px;
                right: -5px;
                width: 20px;
                height: 20px;
                display: block;
                background-image: url('/image/tick.png');
                background-size: cover;
                background-position: center;
            }

        }


        #input-option-colour{
            flex-grow: 1;
            max-width: calc(100% - 0);
            @media (max-width: 767px) {

                max-width: 100%;


            }
            margin-left: -10px;
            margin-right: 10px;
            label{
                padding-left: 10px;
                margin-bottom: 0;
            }
            @media (max-width: 767px) {
                font-size: 16px;
            }
        }
        .form-control{
            flex-grow: 1;
            max-width: calc(100% - 100px);
            @media (max-width: 767px) {

                max-width: 100%;


            }
            background-color: #fff;
            border: 1px solid #ccc;
            //padding: 0.5em 3.5em 0.5em 1em;
            height: 39px;
            font-size: 15px;
            border-radius: 0px;
            margin: 0;
            box-shadow: none;
            @media (max-width: 767px) {
                font-size: 16px;
            }
        }
        .radio{
            margin: 0;
            margin-bottom: 10px;
            label{
                flex-basis: 100%;
                max-width: 100%;

                img{
                    border: 2px solid #e8e8e8;
                }

            }
        }
        label{
            font-size: 21px;
            font-weight: bold;
            margin-bottom: 20px;
        }


        .text-danger{
            text-align: right;
            margin-top: -10px;
            margin-bottom: 10px;
        }
        .form-group{


                 margin-bottom: 15px;
            border-bottom: 1px solid #eaeaea;
        padding-bottom: 15px;

            select.form-control {
                cursor: pointer;
                width: 100%;
                background-color: #fff;
                border: 1px solid #ccc;
                border-radius: 0px;
                display: inline-block;
                font: inherit;
                line-height: 1.5em;
                padding: 0.5em 3.5em 0.5em 1em;
                margin: 0;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
                -webkit-appearance: none;
                -moz-appearance: none;
                background-position: 100%;
                background-image: url(/image/selectarrow.png);
                background-size: auto;
                background-repeat: no-repeat;
                border-radius: 0 !important;
                height: 39px;
                font-size: 15px;
                &:focus {
                    box-shadow: none;
                    background-image: url(/image/selectarrowrev.png);
                }
                -webkit-appearance: none;
                -moz-appearance: none;
                -ms-appearance: none;
                -o-appearance: none;
                appearance: none;
                -moz-appearance: none;
                appearance: none;

                text-overflow: ''; /* this is important! */

                @media (max-width: 767px) {
                font-size: 16px;
            }
            }

            select.form-control::-ms-expand {
                display: none;
            }

            &.green{
                select.form-control{
                    background-image: url(/image/selectarrow-green.png);
                    &:focus {
                        box-shadow: none;
                        background-image: url(/image/selectarrowrev-green.png);
                    }

                }
            }
        }

        .embroidery-options{
            >div {
                display: none;
                &:first-child{
                    display: block;
                }
            }

        }

        .sizes{
            display: flex;
            margin-left: -5px;
            margin-right: -5px;
            flex-grow: 1;
            max-width: 100%;
            flex-wrap: wrap;
            .form-group{
                display: block;
                padding-left: 5px;
                padding-right: 5px;
                flex-basis: 70px;
                max-width: 70px;
                margin-bottom: 10px;
                padding-bottom: 0;
                border-bottom: 0;
                label{
                    display: block;
                    flex-basis: 100%;
                    max-width: 100%;
                        font-size: 17px;
                    margin-bottom: 5px;
                    text-align: center;
                    small{
                        &.red{
                            color: $orange;
                        }
                    }
                }

                .form-control{
                    max-width: 100%;
                    text-align: center;
                    padding: 6px 12px;
                    &:disabled {
                        background: #ccc;
                      }
                }
            }
        }
    }



}

.embroidery-image{
    margin-left: 15px;
    border: 1px solid #ccc;

    &:hover, &:focus{
        border: 1px solid #003366;
    }
}

.related-products{
    margin: 40px 0;
}

#product {
    .modal-content{
      h4,h5{
        display: inline-block;
      }
    }
    .bundle-table {
        .bundle-product {
            padding: 8px 0px;
            align-items: center;
            background-color: #f9f9f9;
            border-top: 1px solid #ddd;
            border-bottom: 1px solid #ddd;
            &:nth-child(even) {
                background-color: #fff;
            }
        }
    }
    .bundle-product {

        > div {
            display: inline-block;
        }
        .name {
            font-weight: bold;
            margin: 0px 10px 0px 20px;
            width:31%;
            
            max-width:130px;
            vertical-align: middle;
        }

        .price {
            color: #fff;
            background-color: $blue;
            margin: 0;
            font-size: 16px;
            font-weight: bold;
            border: 0;
            padding: 10px 12px;
            margin: 0px 10px;
        }
        .form-group {

            margin: 0 !important;
            padding: 0 !important;
            border: none !important;
        }
        button {
            border-radius: 0;
            border: none;
            margin: 0px;
            padding: 8.5px 12px;
        }
        .bp-choose{
          width:126px;

        }
        .form-control{
          max-width: none !important;
        }
        
        .bundle-options {
            //margin: 5px 10px;
            display:none !important;
            pointer-events: none;
            transition: 0.2s all;

            .form-group {
                margin-left: 10px !important;
            }
            > div {
                align-items: center !important;
                select {
                    min-width: 133px;
                }
            }
            @media (max-width: 992px) {
                margin-top: 8px;
            }
        }
        .file-loaded {
            opacity: 1;
            margin-left: 10px;
            transition: 0.2s all;
        }
        .bp-toggle {

        }
        &.active {
            .bundle-options, .file-loaded {
                display: flex !important;
                pointer-events: initial;
            }
        }

        select, button {
            &.error {
                box-shadow: 0px 0px 6px #ac293b !important;
            }
        }
    }
}


.chest-logo-badge{
    position: absolute;
    top: 0;
    left: 0;
    width: 120px;
    transform: translate(-25px, -25px);
}
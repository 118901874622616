#product-category{
    
    .row{
        @media (max-width: 575px) {
            margin-left: -8px;
            margin-right: -8px;
        }
    }
    
    .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto{
        
        @media (max-width: 575px) {
            padding-left: 8px;
            padding-right: 8px;
        }
        
    }

    .filter-row{
        padding: 20px 0 5px;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        margin-bottom: 30px;
    }
    .form-group{
        .input-group-addon{
            background: #fff;
            border: 0px;
            font-size: 15px;
            font-weight: bold;
        }
        select {
            cursor: pointer;
            width: 100%;
            background-color: #fff;
            border: 1px solid #ccc;
            border-radius: 0px;
            display: inline-block;
            font: inherit;
            line-height: 1.5em;
            padding: 0.5em 3.5em 0.5em 1em;
            margin: 0;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            -webkit-appearance: none;
            -moz-appearance: none;
            background-position: 100%;
            background-image: url(/image/selectarrow.png);
            background-size: auto;
            background-repeat: no-repeat;
            border-radius: 0 !important;
            height: 39px;
            font-size: 15px;
            -webkit-appearance: none;
            -moz-appearance: none;
             -ms-appearance: none;
              -o-appearance: none;
                 appearance: none;
                 -moz-appearance: none;
            appearance: none;

            text-overflow: ''; /* this is important! */
            &:focus {
                box-shadow: none;
                background-image: url(/image/selectarrowrev.png);
            }
        }
        select::-ms-expand {
    display: none;
}  
    }

    .list-group{
        border-radius: 0px;
        border: 0px;
        a{
            border-radius: 0px;
            border: 0px;
            border-bottom: 1px solid #fff;
            background: #c2c2c2;
            letter-spacing: 2px;
            font-weight: 300;
            font-size: 18px;
            color: #fff;
            margin-bottom: 0px;
            &:hover, &:focus, &.active{
                background: $blue;
            }
        }
        
        .refine{
            margin-bottom: 15px;
            h3{
                background: $blue; 
                padding: 10px 15px;
                    font-size: 19px;
                    color: #fff;
                    margin-bottom: 1px;
                    margin-top: 0;
            }
            h4{
                background: #33cccc; 
                padding: 10px 15px;
                    font-size: 19px;
                    color: #fff;
                    margin-bottom: 10px;
                    margin-top: 0;
            }
            a{
                border-radius: 0px;
                border: 0px;
                letter-spacing: 0px;
                 padding: 6px 15px;
                border-bottom: 0px solid #fff;
                background: #fff;
                font-weight: 300;
                font-size: 18px;
                color: #666;
                margin-bottom: 0px;
                &:hover, &:focus{ 
                    color: #33cccc;
                }
                &.active{
                    font-weight: bold;
                }
                
            }
        }
    }

    
    .category-list-item{
        
        display: block;
        flex-grow: 1;
        flex-basis: 100%;
        max-width: 100%;
        margin-bottom: 30px;
       
        -webkit-transition: all 0s; 
        -moz-transition: all 0s;
        -o-transition: all 0s;
        transition: all 0s; 
        .image{
            -ms-flex-negative: 0;
            border: 4px solid #e8e8e8;
        }
        h2{
            padding: 10px;
            font-size: 17px;
            margin: 0;
            text-align: center;
            color: #666666;
        }
        .view-range{
            flex-basis: 38px;
            font-size: 15px;
            font-weight: 300;
            text-align: center;
            //padding: 10px 20px;
            margin: 0;
            letter-spacing: 1px;
            
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            background:$orange ;
            
            -webkit-transform: perspective(1px) translateZ(0);
            transform: perspective(1px) translateZ(0);
            box-shadow: 0 0 1px rgba(0, 0, 0, 0);
            position: relative;
            -webkit-transition-property: background;
            transition-property: background;
            -webkit-transition-duration: 0.5s;
            transition-duration: 0.5s;
            
            @media (max-width: 991px) {
                font-size: 16px;
                padding: 10px 10px;
            }
           
            @media (max-width: 575px) {
                font-size: 15px;

            }
            
            
            
        }
        
        &:hover, &:focus,&:active {
           
            .view-range{
                 background: $blue;
            }
            
            .image{
                border: 4px solid #ccc;
            }
            
            
        }
    }


}
#footer{

    .border-up{
        background-image: url('/image/stitch.png');
        background-repeat: repeat-x;
        display: block; 
        position: absolute;
        top: 10px;
        left: 0;
        width: 100%;
        height: 1px;
        z-index: 10;
    }

    .border-down{
        background-image: url('/image/stitch-dark.png');
        background-repeat: repeat-x;
        display: block; 
        position: absolute;
        bottom: 10px;
        left: 0;
        width: 100%;
        height: 1px;
        z-index: 10;
    }
    @media(max-width: 991px){
        .text-right{
            text-align: left!important;
            margin-top: 20px;
        }
        
     }
    .footer-inner{
        padding: 30px 0;
        background-image: url('/image/footer-bg.png');
        background-color: $blue;
        background-position: 50% 100%;
        background-size: auto;
        background-repeat: no-repeat;
        position: relative;
        font-size: 14px;
        line-height: 1.6;
        color: #fff;

        a{
            color: #4abdcf;
        }
        

        .border-down{
            background-image: url('/image/stitch.png');
            background-repeat: repeat-x;
            display: block; 
            position: absolute;
            bottom: 10px;
            left: 0;
            width: 100%;
            height: 1px;
            z-index: 10;
        }
        .border-up{
            display: none;
        }
        
        .footer-address{
            line-height: 1.6;
            font-size: 14px;
        }
        
        .map{
            flex-basis: 100%;
            max-width: 100%;
            @media(max-width: 991px){
                margin-bottom: 20px;
            }
            iframe{
                border: 5px solid #cccccc;
                max-width: 100%;
                height: 100%;
                
            }
        }
    }

    
    .footer-menu{
        ul{
            display: flex;
            flex-wrap: wrap;
            li{
                flex-basis: 50%;
                max-width: 50%;
                
                @media (max-width: 1199px) {

                     flex-basis: 100%;
                    max-width: 100%;
                }
                a{
                    color: #fff;
                    line-height: 1.8;
                    font-size: 14px;
                    &:hover, &:focus{
                        color: #4abdcf;
                        text-decoration: none;
                    }
                }
            }
        }
    }
    
    .social{
        
        margin-bottom: 30px;
        ul{
                margin-bottom: 0;
                margin-left: 0;
                li{
                    display: inline-flex;
                    align-items: stretch;
                    padding: 0;
                    a{
                        display: flex;
                        align-items: center;
                        color: #fff;
                        width: 45px;
                        height: 45px;
                        justify-content: center;

                        i{
                            font-size: 20px;
                        }

                        &.twitter{
                            background: #63cdf1;
                            &:hover, &:focus{
                                background: darken(#63cdf1, 10%);
                            }
                        }
                        &.facebook{
                            background: #507cbe;
                            &:hover, &:focus{
                                background: darken(#507cbe, 10%);
                            }
                        }
                        &.instagram{
                            background: #c7c5b3;
                            &:hover, &:focus{
                                background: darken(#c7c5b3, 10%);
                            }
                        }


                    }
                }
            }
    }
    
    
    
    .copyright{
        padding: 30px 0;
        font-size: 13px;
        
        color: #666;
        a{
            color: #4abdcf;
            
        }
        
    }

}